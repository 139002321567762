.tech {
    background: #f7ede8;
    padding-block: 3rem;
    padding-inline: 3rem;
}
.tech-container {
    display: flex;
    justify-content: space-around;
    align-content: center;
    text-align: start;
    padding-block: 5rem;
}
.tech-play {
    margin-top: -12rem;
    margin-left: 8.5rem;
}

.tech-time {
    margin-top: 3.3rem;
}

.tech-card > h5 {
    color: #EF6D58;
    margin-bottom: 0.5rem;
    font-size: 16px;
    font-weight: 400;
}

.tech-card > h3 {
    font-size: 40px;
    font-weight: 800;
    color: #391400;
}

.tech-card > p {
    font-size: 16px;
    font-weight: 400;
    color: #391400;
    opacity: 64%;
    margin-left: -0.2rem;
    margin-top: -1rem;
}
