/**
 * This code was generated by Builder.io.
 */
:root {
  --Text_Light: #fff;
  --Text_Light_Gray: rgba(255, 255, 255, 0.64);
  --Text_Light_Accent: #ef6d58;
  --Text_Dark: #391400;
  --Text_Dark_Gray: rgba(57, 20, 0, 0.64);
  --Text_Dark_Accent: #ef6d58;
}

body {
  font-family: Epilogue, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.agency-home {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.hero-section {
  background-color: #28293e;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 16px 80px 64px;
}

@media (max-width: 991px) {
  .hero-section {
    max-width: 100%;
    padding: 0 20px;
  }
}

.hero-content {
  display: flex;
  width: 100%;
  max-width: 1170px;
  flex-direction: column;
}

@media (max-width: 991px) {
  .hero-content {
    max-width: 100%;
  }
}

.header {
  display: flex;
  width: 100%;
  gap: 20px;
  font-family: Epilogue, sans-serif;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .header {
    max-width: 100%;
  }
}

.logo {
  align-self: start;
  display: flex;
  gap: 15px;
  font-size: 24px;
  color: var(--Text_Light);
  font-weight: 700;
  white-space: nowrap;
  line-height: 1;
  align-items: center;
}

@media (max-width: 991px) {
  .logo {
    white-space: initial;
  }
}

.logo-image {
  aspect-ratio: 1.07;
  object-fit: contain;
  object-position: center;
  width: 46px;
}

.logo-text {
  font-feature-settings: "liga" off, "clig" off;
}

.nav-menu {
  display: flex;
  gap: 40px 100px;
  text-align: center;
  flex-wrap: wrap;
}

@media (max-width: 991px) {
  .nav-menu {
    max-width: 100%;
  }
}

.nav-links {
  color: var(--Text_Light);
  font-feature-settings: "liga" off, "clig" off;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  align-self: start;
  margin-top: 12px;
}

@media (max-width: 991px) {
  .nav-links {
    max-width: 100%;
  }
}

.contact-button {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--Text_Light);
  font-weight: 900;
  white-space: nowrap;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .contact-button {
    white-space: initial;
  }
}

.contact-button-text {
  border-radius: 6px;
  padding: 17px 34px;
  border: 1px solid rgba(255, 255, 255, 0.296);
}

@media (max-width: 991px) {
  .contact-button-text {
    white-space: initial;
    padding: 0 20px;
  }
}

.hero-main {
  margin-top: 114px;
}

@media (max-width: 991px) {
  .hero-main {
    max-width: 100%;
    margin-top: 40px;
  }
}

.hero-content-wrapper {
  gap: 20px;
  display: flex;
}

@media (max-width: 991px) {
  .hero-content-wrapper {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
}

.hero-text {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0px;
}

@media (max-width: 991px) {
  .hero-text {
    width: 100%;
  }
}

.hero-text-content {
  display: flex;
  margin-top: 10px;
  width: 100%;
  flex-direction: column;
  align-items: start;
  font-family: Epilogue, sans-serif;
}

@media (max-width: 991px) {
  .hero-text-content {
    max-width: 100%;
    margin-top: 40px;
  }
}

.hero-subtitle {
  color: var(--Text_Light_Accent);
  font-feature-settings: "liga" off, "clig" off;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.hero-title {
  color: var(--Text_Light);
  font-feature-settings: "liga" off, "clig" off;
  font-size: 72px;
  font-weight: 800;
  line-height: 80px;
  letter-spacing: -2px;
  align-self: stretch;
  margin-top: 24px;
}

@media (max-width: 991px) {
  .hero-title {
    max-width: 100%;
    font-size: 40px;
    line-height: 49px;
  }
}

.hero-description {
  color: var(--Text_Light_Gray);
  font-feature-settings: "liga" off, "clig" off;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  margin-top: 24px;
}

@media (max-width: 991px) {
  .hero-description {
    max-width: 100%;
  }
}

.hero-cta {
  display: flex;
  margin-top: 52px;
  gap: 40px 46px;
}

@media (max-width: 991px) {
  .hero-cta {
    margin-top: 40px;
  }
}

.primary-button {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--Text_Light);
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent;
}

.primary-button-text {
  border-radius: 6px;
  background-color: #ef6d58;
  padding: 17px 23px;
}

@media (max-width: 991px) {
  .primary-button-text {
    padding: 20 20px;
  }
}

.secondary-button {
  color: var(--Text_Light);
  font-feature-settings: "liga" off, "clig" off;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  margin: auto 0;
  /* background-color: transparent; */
}

.hero-quote {
  display: flex;
  margin-top: 108px;
  align-items: start;
  gap: 16px;
  font-size: 16px;
  color: var(--Text_Light_Gray);
  font-weight: 400;
  line-height: 32px;
}

@media (max-width: 991px) {
  .hero-quote {
    margin-top: 40px;
  }
}

.quote-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  margin-top: 4px;
}

.quote-text {
  font-feature-settings: "liga" off, "clig" off;
  flex-basis: auto;
  flex-grow: 1;
}

.hero-image {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
}

@media (max-width: 991px) {
  .hero-image {
    width: 100%;
  }
}

.hero-image-content {
  aspect-ratio: 0.95;
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 0px 0px 0px 0px;
}

@media (max-width: 991px) {
  .hero-image-content {
    max-width: 100%;
    margin-top: 40px;
  }
}

.main-nav a {
  color: var(--text-dark);
  text-decoration: none;
}

.about-section {
  display: flex;
  align-items: center;
  padding: 4rem 2rem;
}

.about-content {
  flex: 1;
}

.about-title {
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.about-description {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.about-subtitle {
  color: var(--text-dark-gray);
  margin-bottom: 2rem;
}

.cta-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: var(--text-dark-accent);
  color: var(--text-light);
  text-decoration: none;
  border-radius: 6px;
  font-weight: 700;
}

.about-image {
  max-width: 50%;
  height: auto;
}

/* ... Rest of the CSS styles ... */
