/**
 * This code was generated by Builder.io.
 */
:root {
  --text-light: #fff;
  --text-dark: #391400;
  --text-accent: #ef6d58;
  --text-light-gray: rgba(255, 255, 255, 0.64);
  --text-dark-gray: rgba(57, 20, 0, 0.64);
  --bg-primary: #28293e;
  --bg-secondary: #fdf0e9;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Epilogue, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 1.5;
  color: var(--text-dark);
}

.app {
  overflow: hidden;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 80px;
  background-color: var(--bg-primary);
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.logo-image {
  width: 46px;
  height: auto;
}

.logo-text {
  color: var(--text-light);
  font-size: 24px;
  font-weight: 700;
}

.main-nav {
  display: flex;
  align-items: center;
}

.nav-list {
  display: flex;
  list-style: none;
  gap: 40px;
}

.nav-list a {
  color: var(--text-light);
  text-decoration: none;
  font-size: 16px;
}

.contact-button {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.295);
  color: var(--text-light);
  padding: 17px 34px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  margin-left: 40px;
}

.hero {
  background-color: var(--bg-primary);
  display: flex;
  padding: 64px 80px;
  color: var(--text-light);
}

.hero-content {
  flex: 1;
}

.hero-title {
  font-size: 72px;
  font-weight: 800;
  line-height: 80px;
  letter-spacing: -2px;
  margin-bottom: 24px;
}

.hero-subtitle {
  display: block;
  color: var(--text-accent);
  font-size: 16px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.hero-description {
  font-size: 16px;
  color: var(--text-light-gray);
  margin-bottom: 52px;
}

.hero-cta {
  display: flex;
  gap: 40px;
  margin-bottom: 108px;
}

.primary-button {
  background-color: var(--text-accent);
  color: var(--text-light);
  border: none;
  padding: 17px 23px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
}

.secondary-link {
  color: var(--text-light);
  text-decoration: none;
  font-size: 16px;
  align-self: center;
}

.hero-quote {
  display: flex;
  align-items: start;
  gap: 16px;
}

.quote-icon {
  width: 48px;
  height: auto;
}

.quote-text {
  font-size: 16px;
  color: var(--text-light-gray);
}

.hero-image {
  flex: 1;
}

.hero-illustration {
  width: 100%;
  height: auto;
}

.about {
  background-color: var(--bg-secondary);
  display: flex;
  padding: 192px 80px;
}

.about-image {
  flex: 1;
}

.about-illustration {
  width: 100%;
  height: auto;
}

.about-content {
  flex: 1;
  padding-left: 80px;
}

.section-title {
  color: var(--text-accent);
  font-size: 16px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.about-heading {
  font-size: 56px;
  font-weight: 800;
  line-height: 64px;
  letter-spacing: -1px;
  margin-bottom: 26px;
}

.about-description {
  font-size: 20px;
  margin-bottom: 18px;
}

.about-detail {
  color: var(--text-dark-gray);
  margin-bottom: 55px;
}

.about-cta {
  background-color: var(--text-light);
  color: var(--text-dark);
  border: none;
  padding: 17px 33px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 3px 9px rgba(57, 20, 0, 0.08);
}

.stats {
  display: flex;
  justify-content: space-between;
  padding: 64px 80px;
  background-color: var(--bg-secondary);
}

.stat-item {
  text-align: center;
  padding: 42px 48px;
  border: 1px solid #f3d1bf;
}

.stat-value {
  font-size: 40px;
  font-weight: 800;
  line-height: 1.2;
  display: block;
  margin-bottom: 13px;
}

.stat-label {
  font-size: 20px;
}

.process {
  padding: 80px;
  background-color: var(--bg-secondary);
}

.process-content {
  display: flex;
  margin-top: 35px;
}

.process-description {
  flex: 1;
}

.process-heading {
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  margin-bottom: 31px;
}

.process-text {
  color: var(--text-dark-gray);
  margin-bottom: 48px;
}

.read-more {
  color: var(--text-dark);
  text-decoration: none;
}

.process-steps {
  flex: 1;
}

.process-step {
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
}

.step-number {
  font-size: 72px;
  font-weight: 800;
  line-height: 1;
  color: var(--text-dark);
}

.step-description {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: var(--text-dark);
  align-self: center;
}

.video-section {
  display: flex;
  padding: 80px;
  background-color: var(--bg-secondary);
}

.video-container {
  flex: 1;
  position: relative;
}

.video-thumbnail {
  width: 100%;
  height: auto;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-duration {
  position: absolute;
  bottom: 24px;
  left: 24px;
  background-color: rgba(87, 11, 0, 1);
  color: var(--text-light);
  padding: 1px 9px;
  border-radius: 6px;
}

.video-content {
  flex: 1;
  padding-left: 80px;
}

.video-title {
  color: var(--text-accent);
  font-size: 16px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 17px;
}

.video-heading {
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  margin-bottom: 47px;
}

.video-description {
  color: var(--text-dark-gray);
}

.features {
  display: flex;
  padding: 80px;
  background-color: var(--bg-secondary);
}

.features-content {
  flex: 1;
}

.features-heading {
  font-size: 56px;
  font-weight: 800;
  line-height: 64px;
  letter-spacing: -1px;
  margin-bottom: 26px;
}

.features-description {
  font-size: 20px;
  margin-bottom: 44px;
}

.feature-list {
  list-style: none;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 14px;
}

.feature-icon {
  width: 32px;
  height: auto;
}

.features-image {
  flex: 1;
}

.features-illustration {
  width: 100%;
  height: auto;
}

.services {
  padding: 80px;
  background-color: var(--bg-secondary);
}

.services-heading {
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  margin-bottom: 35px;
}

.service-cards {
  /* display: flex;
  gap: 20px; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  /* margin-bottom: 64px; */
}

.service-card {
  flex: 1;
  border: 1px solid #f3d1bf;
  border-radius: 6px;
  padding: 50px 48px;
}

.service-icon {
  width: 80px;
  height: auto;
  margin-bottom: 41px;
}

.service-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 19px;
}

.service-description {
  color: var(--text-dark-gray);
  margin-bottom: 48px;
}

.service-cta {
  color: var(--text-dark);
  text-decoration: none;
}

.portfolio {
  padding: 92px 80px;
  background-color: var(--bg-primary);
  color: var(--text-light);
}

.portfolio-heading {
  font-size: 56px;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  margin-bottom: 36px;
}

.portfolio-categories {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 68px;
}

.category-button {
  background: none;
  border: none;
  color: var(--text-light-gray);
  font-size: 16px;
  cursor: pointer;
}

.category-button.active {
  color: var(--text-light);
}

.category-count {
  font-size: 14px;
  margin-left: 5px;
}

.portfolio-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-bottom: 64px;
}

.portfolio-item {
  position: relative;
  overflow: hidden;
}

.portfolio-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.portfolio-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 48px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
}

.portfolio-category {
  display: inline-block;
  background-color: var(--text-light);
  color: var(--text-dark);
  padding: 3px 16px;
  border-radius: 19px;
  font-size: 10px;
  margin-bottom: 23px;
}

.portfolio-title {
  font-size: 20px;
  font-weight: 800;
  line-height: 1.2;
}

.portfolio-link {
  color: var(--text-light);
  text-decoration: none;
}

.portfolio-cta {
  display: block;
  width: 170px;
  margin: 0 auto;
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.295);
  color: var(--text-light);
  padding: 17px 31px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
}

.team {
  padding: 80px;
  background-color: var(--bg-primary);
  color: var(--text-light);
}

.team-heading {
  font-size: 56px;
  font-weight: 800;
  line-height: 64px;
  letter-spacing: -1px;
  margin-bottom: 64px;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 52px;
}

.team-member {
  border: 1px solid #3a3c56;
  border-radius: 6px;
  padding: 24px;
  text-align: center;
}

.member-image {
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}

.member-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 8px;
}

.member-role {
  color: var(--text-light-gray);
}

.team-navigation {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.nav-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #4e4f6e;
  cursor: pointer;
}

.nav-dot.active {
  background-color: var(--text-accent);
}

.testimonials {
  padding: 124px 80px;
  background-color: var(--bg-secondary);
}

.testimonials-heading {
  font-size: 56px;
  font-weight: 800;
  line-height: 64px;
  text-align: center;
  margin-bottom: 64px;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 65px;
}

.testimonial-card {
  background-color: var(--text-light);
  border: 1px solid #f3d1bf;
  border-radius: 6px;
  padding: 48px;
}

.quote-icon {
  width: 152px;
  height: auto;
  margin-bottom: 36px;
}

.testimonial-content {
  color: var(--text-dark-gray);
  margin-bottom: 52px;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 24px;
}

.author-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.author-name {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 8px;
}

.author-company {
  color: var(--text-dark-gray);
}

.testimonials-cta {
  display: block;
  width: 138px;
  margin: 0 auto;
  background-color: var(--text-light);
  color: var(--text-dark);
  border: none;
  padding: 17px 42px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 3px 9px rgba(57, 20, 0, 0.08);
}

.contact {
  padding: 80px;
  background-color: var(--bg-secondary);
}

.contact-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}

.info-item {
  flex: 1;
  border: 1px solid #f3d1bf;
  border-radius: 6px;
  padding: 35px 48px;
  text-align: center;
}

.info-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 26px;
}

.info-title {
  color: var(--text-accent);
  font-size: 16px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 26px;
}

.info-content {
  font-size: 20px;
  line-height: 1.6;
}

.contact-form-container {
  display: flex;
  background-color: var(--text-accent);
  border-radius: 6px;
  overflow: hidden;
}

.form-content {
  flex: 1;
  padding: 38px 75px 72px;
  color: var(--text-light);
}

.form-title {
  font-size: 56px;
  font-weight: 800;
  line-height: 64px;
  letter-spacing: -1px;
  margin-bottom: 28px;
}

.form-description {
  color: var(--text-light-gray);
  margin-bottom: 52px;
}

.contact-cta {
  background-color: var(--text-light);
  color: var(--text-dark);
  border: none;
  padding: 17px 32px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0px 3px 9px rgba(57, 20, 0, 0.08);
}

.form-image {
  flex: 1;
}

.contact-illustration {
  width: 30%;
  height: 30%;
  object-fit: cover;
}

.footer {
  background-color: var(--bg-secondary);
  padding: 80px 80px 43px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 68px;
}

.logo-text {
  font-size: 24px;
  font-weight: 700;
  color: var(--text-light);
}

.footer-heading {
  color: var(--text-accent);
  font-size: 16px;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.footer-list {
  list-style: none;
}

.footer-list li {
  margin-bottom: 8px;
}

.footer-list a {
  color: var(--text-dark);
  text-decoration: none;
}

.social-icons {
  width: 160px;
  height: auto;
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 43px;
  border-top: 1px solid #f3d1bf;
}

.copyright {
  color: var(--text-dark-gray);
}

.legal-links {
  display: flex;
  gap: 40px;
}

.legal-link {
  color: var(--text-dark);
  text-decoration: none;
}

@media (max-width: 991px) {
  .header,
  .hero,
  .about,
  .stats,
  .process,
  .video-section,
  .features,
  .services,
  .portfolio,
  .team,
  .testimonials,
  .contact,
  .footer {
    padding-left: 20px;
    padding-right: 20px;
  }
  .portfolio-overlay {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 48px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }

  .hero,
  .about,
  .video-section,
  .features,
  .contact-form-container {
    flex-direction: column;
  }

  .hero-title,
  .about-heading,
  .features-heading,
  .team-heading,
  .testimonials-heading,
  .form-title {
    font-size: 40px;
    line-height: 51px;
  }

  .service-cards,
  .portfolio-grid,
  .team-grid,
  .testimonials-grid {
    grid-template-columns: 1fr;
  }

  .contact-info {
    flex-direction: column;
    gap: 20px;
  }

  .footer-content {
    flex-wrap: wrap;
    gap: 40px;
  }
}

.contact-section {
  position: relative;
  padding: 100px 80px;
  background-color: var(--bg-light);
}

.contact-decoration {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}

.contact-form {
  flex-basis: 45%;
  background-color: var(--text-light);
  padding: 40px;
  border-radius: 6px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.form-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.form-input,
.form-select,
.form-textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #f3d1bf;
  border-radius: 6px;
  font-family: inherit;
  font-size: 16px;
}

.form-select-wrapper {
  position: relative;
}

.form-select-wrapper::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.form-textarea {
  height: 120px;
  resize: vertical;
}

.form-submit {
  width: 100%;
  background-color: var(--text-dark-accent);
  color: var(--text-light);
  border: none;
  padding: 15px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-submit:hover {
  background-color: #ff7e68;
}

.contact-illustration {
  flex-basis: 45%;
  max-width: 100%;
  height: auto;
}

@media (max-width: 991px) {
  .contact-section {
    padding: 60px 20px;
  }

  .contact-content {
    flex-direction: column;
  }

  .contact-form,
  .contact-illustration {
    flex-basis: 100%;
  }

  .contact-illustration {
    margin-top: 40px;
  }
}
