.project {
    background: #f7ede8;
    padding-block: 3rem;
    padding-inline: 3rem;
}
.project1 {

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-inline: 7rem;
    /* border: 1px solid #391400; */
}
.project-card {
    border: 1px solid #F3D1BF;
    width: 50%;
    justify-content: center;
    align-content: center;
}

.project-card  > h1, p {
    margin-left: 2rem;
    color: #391400;
}

.project-card  > h1 {
    font-size: 40px;
    font-weight: 800;
}

.project-card  > p {
    font-size: 20px;
    font-weight: 400;
}