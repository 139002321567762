.feature {
    background: #f7ede8;
    padding-block: 3rem;
    padding-inline: 3rem;
}
.feature-container {
    display: flex;
    justify-content: space-around;
    /* border: 1px solid red; */
    
 }

 .feature-title {
    text-align: left;
    margin-top: 2rem;
 }

 .feature-title > h5 {
    color: #EF6D58;
    font-size: 16px;
    font-weight: 400;
 }

 .feature-title > p {
    color: #391400;
    margin-left: -0.2rem;
    font-size: 20px;
    font-weight: 400;
 }

 .feature-title > h6 {
    color: #391400;
    font-size: 16px;
    font-weight: 400;
    margin-left: -0.2rem;
 }
.feature-card {
    display: flex;
    justify-content: space-around;
    align-content: center;
    text-align: center;
    margin-inline: -11rem;
    width: 50%;
    /* border: 1px solid red; */
}

.feature-card1 {
    border: 1px solid #F3D1BF;
    text-align: left;
    background: #FFFFFF;
    padding-block: 2rem;
    padding-inline: 1rem;
    width: 48%;
 
}

.feature-card1 > p {
    font-size: 16px;
    font-weight: 400;
    color: #391400;
    opacity: 64%;
    margin-left: -0.2rem;
    /* margin-top: -2rem; */
}

.feature-card2 {
    border: 1px solid #F3D1BF;
    text-align: left;
    padding-block: 2rem;
    margin-top: 4rem;
    padding-left: 1rem;
    margin-left: -0.1rem;
    width: 48%;
    
}

.feature-card2 > p {
    font-size: 16px;
    font-weight: 400;
    color: #391400;
    opacity: 64%;
    margin-left: -0.2rem;
    /* margin-top: -2rem; */
}

