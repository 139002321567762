.hero {
    background: #1e203a;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: start;
    padding-inline: 5rem;
    margin: 0;
    padding-block: 1rem;
}

.hero-title {
    padding-block: 2rem;
    /* margin-block: 2rem; */
}

.hero-title > p {
    margin-top: -2rem;
    margin-left: -0.1rem;
    color: #FFFFFF;
    opacity: 64%;
}

.hero-ban > h5 {
    color: #EF6D58;
    margin-bottom: -4rem;
    margin-left: -0.1rem;
    font-size: 16px;
    font-weight: 400;
              
}
.hero-ban > h1{
    font-size: 4.5rem;
    padding-top: 2rem;
    background-image: url(../images/Star.png);
    background-size: 28%;
    background-repeat: no-repeat;
    /* background-position: top right; */
    background-position: calc(100% - 6.5rem)0.1rem;
    

}

 .hero-btn {
    background: #EF6D58;
    color: #FFFFFF;
    padding: 0.5rem;
    border-radius: 5px;
    border: none;

}

.hero-btn2 {
    background: #1e203a;
    color: #FFFFFF;
    padding-inline: 2rem;
    border: none;
}

.hero-banner {
    display: flex;
    padding-block: 2rem;
    gap: 2rem;
    margin-top: 3rem;

    
}

.heroes > h6 {
    margin-top: -0.5rem;
}
.heroes > p {
    opacity: 64%;
    margin-top: -0.1rem;
    color: #FFFFFF;
    margin-left: -0.1rem;
}


