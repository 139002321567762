.navbar {
    display: flex;
    /* height: 60px; */
    background:  #1e203a;
    ;
    align-items: center;
    justify-content: center;
    padding-inline: 5rem;
    padding-block: 1rem;
    /* border: 1px solid blue; */
  
  }
  
  .navbar >   .nav-logo {
    border-bottom: 5rem;
    padding-left: 2.5rem;
    /* padding: 1rem; */
  }

  .nav-logo > img {
    width: 100%;

  }
  
  .nav-items {
    display: flex;
    color: #FFFFFF;
    text-decoration: none;
    width: 50%;
    padding-inline: 3rem;
    text-align: center;
    margin-inline: 4rem;
    /* gap: 2rem; */
    position: relative;
    justify-content: space-around;
    opacity: 0.9;
    font-size: 16px;
    /* border: 1px solid red; */
  }

  .nav-items > a {
    text-decoration: none;
    color: #FFFFFF;
  }

  /* .navbar > .nav-item > a {
    color: #545454;
    font-size: 16px;
    text-decoration: none;
    margin: 15px;
    justify-content: space-around;
    margin-right: 5rem;
    position: relative;
    opacity: 0.9;
  } */
  
  /* .navbar > .nav-item > a:hover {
    opacity: 1;
  }
   */
  .navbar > .nav-item > a::before {
    content: '';
    position: absolute;
    left: o;
    bottom: 0;
    width: 0;
    height: 2px;
    background: #FDF4F5;
    transition: all .45%;
  }
  
  .navbar > .nav-item > a:hover::before {
    width: 100%;
  }
  
  .navbar > .nav-toggle {
    display: none;
  }
  
  /* .navbar > .nav-item > .btn {
    background-color: #0000B5;
    color: #FDF4F5;
    /* padding: 0.5rem; */
    /* margin-bottom: 1rem;
    border-radius: 8px;
    cursor: pointer;
    display: flex-end;

    
  } */ 

 .nav-foot{
  display: flex;
  flex-grow: 1;
   
  justify-content: end;
  align-items: center;
  gap: 1rem;

 }
 

 .btn-1 {
    color: #FFFFFF;
    font-size: 16px;
    background-color: #1e203a;
    border: 1px solid #FFFFFF;
    padding: 0.5rem;
    border-radius: 5px;
    /* display: inline-block; */
    width: 6rem;

 }

 .btn-2 {
  
  color: #ece7e6;
  background-color: #0000B5;
  font-size: 16px;
  padding: 13px 15px;
  border-radius: 0.4rem;
  /* margin-top: 15px; */
  /* margin-left: 5rem; */
  width: 6rem; 
  border: none;

 }