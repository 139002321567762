.foot{
  background: #f7ede8;
  padding-block: 3rem;
  padding-inline: 3rem;
}
.footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: start;
    /* margin-block: 1rem; */
    margin-inline: -8rem;
    background: #f7ede8;
    color: #391400;
    padding-block: 6rem;
    /* border: 1px solid red; */
}

.footer-card {
    margin-top: -10rem;

}


.footer-card1 {
  margin-top: -4rem;
  
}

.footer-card1 > p {
   
    font-size: smaller;
    text-align: start;
    margin-left: -0.1rem;
    color: #391400;

}


.footer-card1 > h5 {
  color: #EF6D58;
    font-size: 16px;
    font-weight: 400;
}
.footer-card2 {
    margin-top: -6rem;
    
  }

  .footer-card2 > p {
    font-size: smaller;
    
  }

  .footer-card3 {
    margin-top: -10rem;
    font-size: smaller;
    
  }

  .footer-border {
    border-bottom: 1px solid #F3D1BF;
    margin-top: -3rem;
    opacity: 60%;
}

.footing {
  display: flex;
}
.footing > p {
  font-size: 16px;
  font-weight: 400;
  color: #391400;
  opacity: 64%;
}
.footed {
  display: flex;
  margin-left: 35rem;
}

.foots > p {
  font-size: 16px;
  font-weight: 400;
  color: #391400;
  opacity: 64%;
}